/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import {graphql, useStaticQuery} from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';

import {rhythm} from '../utils/typography';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: {regex: "/profile-pic.jpg/"}) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            linkedin
            github
            gitlab
          }
        }
      }
    }
  `);

  interface Props {
    href: string;
    children?: any;
  }

  const SocialLink = ({href, children}: Props) => (
    <a href={href} target="_blank" date-id={children.toString().toLowerCase()}>
      {children}
    </a>
  );

  const {author, social} = data.site.siteMetadata;
  return (
    <div style={{display: `flex`, marginBottom: rhythm(2.5)}}>
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <span>
        Written by <strong>{author}</strong> originally from Israel, currently lives and works in
        Berlin building useful things.
        <br />
        Follow me on: {` `}
        <SocialLink href={`https://twitter.com/${social.twitter}`}>Twitter</SocialLink>
        {` `} or {` `}
        <SocialLink href={`https://www.linkedin.com/in/${social.linkedin}`}>LinkedIn</SocialLink>
      </span>
    </div>
  );
};

export default Bio;
